<template>
  <div>
    <ModalAddPackage
      v-if="showAddPackage"
      :showModal="showAddPackage"
      :onClickCancel="hideModal"
    />

    <ModalEditPackage
      v-if="showEditPackage"
      :showModal="showEditPackage"
      :packageId="selectedPackage.Id"
      :onClickCancel="hideModal"
    />

    <ModalDeletePackage
      v-if="showDeletePackage"
      :showModal="showDeletePackage"
      :packageId="selectedPackage.Id"
      :onClickCancel="hideModal"
    />

    <ui-page-hero id="id_title_packages" :title="'Packages'" />

    <div
      class="actionBar has-background-white-ter has-padding has-margin-bottom"
    >
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <a @click="setShowAddPackage" class="is-small">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'plus']" />
            </span>
            <span>Create package</span>
          </a>
        </div>
      </div>
    </div>

    <div>
      <table
        v-if="pckgs && pckgs.length > 0"
        class="table is-fullwidth is-striped"
      >
        <thead>
          <th width="150">Active</th>
          <th>Name</th>
          <th width="150" class="has-text-right">Bundles</th>
          <th width="150" class="has-text-right">Monthly fee</th>
          <th width="150" class="has-text-right">Subscriptions</th>
          <th width="80">&nbsp;</th>
        </thead>
        <transition-group
          name="staggered-fade"
          v-bind:css="false"
          v-on:before-enter="beforeEnter"
          v-on:enter="enter"
          tag="tbody"
        >
          <tr
            v-for="(pckg, index) in activePackages"
            :key="'tr_' + index"
            :data-index="index"
          >
            <td>
              <div class="tags">
                <span v-if="pckg.IsActive" class="tag is-success"
                  ><a @click="deactivate(pckg)">Active</a></span
                >
              </div>
            </td>
            <td>
              {{ pckg.Name }}
            </td>
            <td class="has-text-right">{{ pckg.Bundles.length }}</td>
            <td class="has-text-right">
              {{ pckg.MonthFee | toCurrency }}
            </td>
            <td class="has-text-right">
              {{ pckg.NrOfSubscriptions ? pckg.NrOfSubscriptions : 0 }}
            </td>
            <td class="has-text-right">
              <div class="field is-grouped has-text-right">
                <p class="control">
                  <a @click="setShowEditPackage(pckg)" class="is-small">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'edit']" />
                    </span>
                  </a>
                </p>
              </div>
            </td>
          </tr>
        </transition-group>
      </table>

      <table
        v-if="pckgs && pckgs.length > 0"
        class="table is-fullwidth is-striped"
      >
        <thead>
          <th width="150">Active</th>
          <th>Name</th>
          <th width="150" class="has-text-right">Bundles</th>
          <th width="150" class="has-text-right">Monthly fee</th>
          <th width="150" class="has-text-right">Subscriptions</th>
          <th width="80">&nbsp;</th>
        </thead>
        <transition-group
          name="staggered-fade"
          v-bind:css="false"
          v-on:before-enter="beforeEnter"
          v-on:enter="enter"
          tag="tbody"
        >
          <tr
            v-for="(pckg, index) in inactivePackages"
            :key="'tri_' + index"
            :data-index="index"
          >
            <td>
              <div class="tags">
                <span v-if="!pckg.IsActive" class="tag"
                  ><a @click="activate(pckg)">Inactive</a></span
                >
              </div>
            </td>
            <td>
              {{ pckg.Name }}
            </td>
            <td class="has-text-right">{{ pckg.Bundles.length }}</td>
            <td class="has-text-right">
              {{ pckg.MonthFee | toCurrency }}
            </td>
            <td class="has-text-right">{{ pckg.NrOfSubscriptions }}</td>
            <td class="has-text-right">
              <div class="field is-grouped has-text-right">
                <p class="control">
                  <a @click="setShowEditPackage(pckg)" class="is-small">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'edit']" />
                    </span>
                  </a>
                </p>
                <p class="control">
                  <span class="has-text-danger">
                    <a
                      @click="setShowDeletePackage(pckg)"
                      class="has-text-danger"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </a>
                  </span>
                </p>
              </div>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import staggeredAnimation from '@/mixins/staggeredAnimation'

const ModalAddPackage = () => import('@/components/Packages/ModalAddPackage')
const ModalDeletePackage = () =>
  import('@/components/Packages/ModalDeletePackage')
const ModalEditPackage = () => import('@/components/Packages/ModalEditPackage')

export default {
  components: {
    ModalAddPackage,
    ModalDeletePackage,
    ModalEditPackage,
  },

  mixins: [staggeredAnimation],

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isLoading: false,
      selectedPackage: null,
      showAddPackage: false,
      showDeletePackage: false,
      showEditPackage: false,
    }
  },

  computed: {
    ...mapState('packageStore', ['pckgs']),

    activePackages() {
      let self = this
      let p = []

      if (self.pckgs && self.pckgs.length > 0) {
        p = self.pckgs.filter((p) => p.IsActive)
      }

      return p
    },

    inactivePackages() {
      let self = this
      let p = []

      if (self.pckgs && self.pckgs.length > 0) {
        p = self.pckgs.filter((p) => !p.IsActive)
      }

      return p
    },
  },

  created() {
    if (this.pckgs.length === 0) {
      this.getAllPackages(this.channelId)
    }
  },

  methods: {
    ...mapActions('packageStore', [
      'getAllPackages',
      'activatePackage',
      'deactivatePackage',
    ]),

    activate(pckg) {
      this.activatePackage(pckg.Id)
    },

    deactivate(pckg) {
      this.deactivatePackage(pckg.Id)
    },

    setShowAddPackage() {
      this.showAddPackage = true
    },

    setShowDeletePackage(packageObj) {
      this.showDeletePackage = true
      this.selectedPackage = packageObj
    },

    setShowEditPackage(packageObj) {
      this.showEditPackage = true
      this.selectedPackage = packageObj
    },

    hideModal() {
      this.showAddPackage = false
      this.showDeletePackage = false
      this.showEditPackage = false
      this.selectedPackage = null
    },
  },
}
</script>
