export default {
  methods: {
    beforeEnter: function(el) {
      el.style.opacity = 0
    },

    enter: function(el, done) {
      var delay = el.dataset.index * 50
      setTimeout(function() {
        el.style.transition = 'opacity 0.3s'
        el.style.opacity = '1'
      }, delay)
    },

    leave: function(el, done) {
      var delay = el.dataset.index * 50
      setTimeout(function() {
        el.style.transition = 'opacity 0.3s'
        el.style.opacity = '0'
      }, delay)
    },

    afterLeave: function(el) {
      if (el) {
        el.parentNode.removeChild(el)
      }
    },
  }
};